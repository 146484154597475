@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html {
  @apply bg-deep-blue;
}

sup {
  @apply normal-case;
}

p {
  a {
    @apply font-bold;

    &:hover {
      @apply underline;
    }
  }
}
